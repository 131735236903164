/* eslint-disable max-len */
export const mostFrequentQuestions = [
  { question: 'Les retouches & prix / catégorie Décoration',
    answers: [
      { text: 'Ourlets rideaux',
        subAnswers: [
          '< 199 cm : 20€',
          '> 200 cm : 30€',
          'supplément doublure cousue : 10€',
          'supplément fil de plomb : 10€',
        ] },
      { text: 'Raccords de rideaux (par le bas pour la longueur ou par les côtés pour la largeur)',
        subAnswers: [
          '< 199 cm : 30€',
          '> 200 cm : 40€',
          'supplément doublure cousue : 10€',
        ] },
      { text: 'Coussins / récupération des chutes de rideaux',
        subAnswers: [
          'Petit < 45 cm par côté : 14€',
          'Moyen > 45 cm par côté : 18€',
          'Zip : 15€',
          'Zip invisible : 22€',
        ] },
      { text: 'Broderies',
        subAnswers: [
          'Initiales : 10€',
          'Un mot (10 lettres - petite police) : 15€',
          'Un mot (10 lettres - grande police) : 45€',
          'Un mot (10 lettres - grande police lettres pleines) : 80€',
          'Dessins possibles aussi, plus de renseignements sur le site www.tilli.fr',
        ] },
    ] },
  { question: 'Les retouches ont-elles lieu sur place ?',
    answers: ['NON ! Les retouches n’ont pas lieu sur place. Le Tilliste vient conseiller le client & prendre les mesures puis repart en atelier pour effectuer les retouches et pour une finition de meilleure qualité. Ensuite, le client est livré à date et heure convenues en RDV avec le couturier.',
      'Si les rideaux sont particulièrement lourds ou difficiles à décrocher, le client pourra nous contacter pour nous le préciser et nous ferons le maximum pour que les retouches se fassent exceptionnellement sur place. '] },
  { question: 'Pré-requis avant chaque RDV',
    answers: ['Rideaux idéalement lavés & repassés',
      'Installés sur leur tringle depuis au moins 48h pour la bonne détente des fibres et qu’il ne se détendent pas post-retouches effectuées.'] },
  { question: 'Nos couturiers modélistes',
    answers: [
      'Nos Tillistes sont des couturiers modélistes avec un regard stylistique sélectionnés avec soin sur leurs savoirs faire et leur savoir être. On les adore, vos clients vont les adorer aussi ;) La retouche ne sera plus une corvée pour vous clients mais un moment humain et agréable avec son couturier sur-mesure.',
      'Nos Tillistes sont des couturiers et maîtrise par conséquent les travaux de couture uniquement',
      'Pour des questions d’assurances, nos Tillistes ne montent pas en haut d’une échelle pour accrocher les rideaux du clients. Bien en informer le client. Votre partenaire Hellocasa peut intervenir en plus pour la pose des tringles et rideaux pour vos clients s’ils le souhaitent.',
    ],
  },
  { question: 'Quels sont les délais ?',
    answers: ['3 à 5 jours en général',
      'Le client fixera la date de rendu avec son couturier en RDV :)',
      'Pour toute demande urgente, il peut nous contacter au 01.84.80.05.39, nous ferons le maximum !'] },
  { question: 'Où vous déplacez-vous ?',
    answers: [
      'Nous nous déplaçons à Paris et sa proche banlieue, Lyon, Marseille, Aix en Provence, Bordeaux, Toulouse, Lille, Rennes, Nantes, Strasbourg, Nice, Cannes, Montpellier, Nimes, Metz, Grenoble, Caen, Rouen et Tours.',
      'POPUP',
      '“Mon client habite-t-il à moins de 5 minutes à pied d’un arrêt de métro/RER ?” OUI - alors on y va !'] },
  {
    question: 'Paiement des retouches',
    answers: ['Vous n’avez rien à faire, on s’occupe de tout dès la prise de commande.'],
  },
];

export const serviceQuestions = [
  { question: 'Tilli, qu’est-ce que c’est ?',
    answers: [
      'Tilli, c‘est le premier service de couturiers à domicile. Un service vous permettant de bichonner votre client dans le prolongement de son expérience boutique. À partir de maintenant vous ne laissez plus votre client face à un problème de fit, vous l’accompagnez jusqu’au dernier point de couture.',
    ] },
  { question: 'Comment ça marche ? ',
    answers: [
      '(1) À date et heure de votre choix, nos couturiers se déplacent chez vos clients. Hop, votre client repart avec ses achats et pourra retrousser son jean ce soir en attendant son couturier le lendemain ;)',
      '(2) Le RDV : dure une 30ène de minute pour que votre client puisse prendre le temps avec son couturier d’être conseillé au mieux, d’essayer avec les bonnes chaussures ou le bon t-shirt et valider définitivement les retouches à effectuer pendant son RDV. Validation du devis.',
      '(3) 3 à 5 jours plus tard, le client récupère ses affaires à son domicile.',
    ],
  },
  { question: 'Que doit préparer mon client avant son RDV ?',
    answers: ['Rideaux idéalement lavés & repassés',
      'Installés sur leur tringle depuis au moins 48h pour la bonne détente des fibres et qu’il ne se détendent pas post-retouches effectuées.'] },
  { question: 'Les retouches ont-elles lieu sur place ?',
    answers: ['NON ! Les retouches n’ont pas lieu sur place. Le Tilliste vient conseiller le client & prendre les mesures puis repart en atelier pour effectuer les retouches et pour une finition de meilleure qualité. Ensuite, le client est livré à date et heure convenues en RDV avec le couturier.',
      'Si les rideaux sont particulièrement lourds ou difficiles à décrocher, le client pourra nous contacter pour nous le préciser et nous ferons le maximum pour que les retouches se fassent exceptionnellement sur place. '] },
  { question: 'Comment prendre rendez-vous pour mon client ? ',
    answers: [
      'Rien de plus simple ! Cliquez sur « prendre RDV » et suivez les étapes de validation des retouches pour votre client. On vous indiquera un devis à titre indicatif, qui n’est pas figé. Le client pourra toujours ajouter des pièces à retoucher ou à broder pendant son rendez-vous.',
    ],
  },
  { question: 'Faire un devis, c’est obligatoire ? ',
    answers: [
      'Non ! Mais on vous le conseille pour orienter votre client ;-)',
      'Pas d’inquiétude, si vous devez aller vite, passez l’étape du devis.',
      'N’oubliez pas en revanche de préciser la matière !  Cela nous permet de vous envoyer le couturier qualifié selon la demande. Chaque matière est spécifique, et nous avons des couturiers aux compétences multiples et très différentes.',
    ],
  },
  { question: 'Qui sont les Tillistes ?',
    answers: [
      'Nos Tillistes sont des couturiers modélistes avec un regard stylistique sélectionnés avec soin sur leurs savoirs faire et leur savoir être. On les adore, vos clients vont les adorer aussi ;) La retouche ne sera plus une corvée pour vous clients mais un moment humain et agréable avec son couturier sur-mesure.',
      'Nos Tillistes sont des couturiers et maîtrise par conséquent les travaux de couture uniquement',
      'Pour des questions d’assurances, nos Tillistes ne montent pas en haut d’une échelle pour accrocher les rideaux du clients. Bien en informer le client. Votre partenaire Hellocasa peut intervenir en plus pour la pose des tringles et rideaux pour vos clients s’ils le souhaitent.',
    ],
  },
  { question: 'Quels sont les délais ? ',
    answers: [
      '3 à 5 jours en général',
      'Le client fixera la date de rendu avec son couturier en RDV :)',
      'Pour toute demande urgente, il peut nous contacter au 01.84.80.05.39, nous ferons le maximum !',
    ],
  },
  { question: 'Où le service Tilli est-il desservi ? ',
    answers: [
      'Paris et sa proche banlieue, Lyon, Villeurbanne, Marseille, Aix en Provence et Bordeaux',
      'POPUP',
      '“Mon client habite-t-il à moins de 5 minutes à pied d’un arrêt de métro/RER ?” OUI - alors on y va !',
    ],
  },
  { question: 'Tarifs',
    answers: [
      { text: 'Ourlets rideaux',
        subAnswers: [
          '< 199 cm : 20€',
          '> 200 cm : 30€',
          'supplément doublure cousue : 10€',
          'supplément fil de plomb : 10€',
        ] },
      { text: 'Raccords de rideaux (par le bas pour la longueur ou par les côtés pour la largeur)',
        subAnswers: [
          '< 199 cm : 30€',
          '> 200 cm : 40€',
          'supplément doublure cousue : 10€',
        ] },
      { text: 'Coussins / récupération des chutes de rideaux',
        subAnswers: [
          'Petit < 45 cm par côté : 14€',
          'Moyen > 45 cm par côté : 18€',
          'Zip : 15€',
          'Zip invisible : 22€',
        ] },
      { text: 'Broderies',
        subAnswers: [
          'Initiales : 10€',
          'Un mot (10 lettres - petite police) : 15€',
          'Un mot (10 lettres - grande police) : 45€',
          'Un mot (10 lettres - grande police lettres pleines) : 80€',
          'Dessins possibles aussi, plus de renseignements sur le site www.tilli.fr',
        ] },
    ] },
  { question: 'Politique d’offre des retouches adaptée à votre enseigne !',
    answers: [
      'Possibilité d’offrir toutes les retouches à certains de vos clients.',
      'Contactez-nous dans un premier temps par mail à contact@tilli.fr ou via la chat en bas à droite de la plateforme pour nous prévenir si vous souhaitez offrir certaines retouches à vos clients en nous précisant leurs nom & prénom :)',
    ],
  },
  { question: 'Existe-t-il un minimum de commande ? ',
    answers: [
      'Minimum de commande 20€ (un ourlet)',
      '+ frais de déplacements 5€ - parfois 15€ si le client habite dans une zone moins bien desservie. Pour le savoir :',
      'POPUP',
    ],
  },
  { question: 'Qu’est-il compris dans les tarifs ?',
    answers: [
      'Le déplacement du couturier au domicile du client, la réalisation des retouches et la livraison des articles',
      '+ un rendez-vous de réajustement si besoin',
    ],
  },
  { question: 'Comment encaisser mon client ?',
    answers: [
      'PAS d’encaissement de votre côté !!!',
      'On gère à partir de votre prise de commande.',
    ],
  },

  { question: 'Quels sont les moyens de paiement pour le client après ?',
    answers: [
      'Paiement en ligne sécurisé envoyé par mail à la suite de votre prise de RDV pour votre client. Moderne & simple ;)',
      'Si question du client, nous sommes toujours et encore joignable au 01.84.80.05.39',
    ],
  },
];

export const customerCareQuestions = [
  { question: 'Annulations & reports',
    answers: [
      'Le client n‘est pas censé venir vers vous. Le mieux est dans ce cas de lui dire de nous appeler au 01.84.80.05.39 ou de nous envoyer un mail à contact@tilli.fr.',
      'Sachez que nous facturons au client et non à la marque des frais d‘annulation bien spécifié dans le mail de confirmation de sa commande.',
    ],
  },
  { question: 'Modification des coordonnées clients',
    answers: [
      'Idem nous le renvoyer au 01.84.80.05.39 ou par mail.',
      'Sinon il a un joli espace client sur le site tilli.fr et/ ou sur l‘App Tilli. ;)',
    ],
  },
  { question: 'Et si mon client a un problème avec les retouches effectuées ?',
    answers: [
      'Toujours pareil, on s’en charge ;)',
      'Un rendez-vous de réajustement est prévu si votre client n’est pas satisfait de ses retouches.',
      'Et si le problème n‘est pas récupérable - ce qui serait très surprenant, nous travaillons avec AXA assurance afin d‘assurer toutes vos pièces.',
    ],
  },
  { question: 'Existe-t-il une application ?',
    answers: [
      'Bien sûr ! Préférez la commande via votre App vendeur, comme ça on sait que cela vient de vous ;',
      'Votre client pourra en parallèle télécharger l’app « Tilli » sur l’Apple Store et sur Google Play et suivre sa commande',
    ],
  },
];
